import Form from "./components/form";
import PhoneMask from "./components/phoneMask";
import scrollTo from "./components/scrollToElement";

var main = {
    init: function() {
        $(document).ready(function() {
            main.timer();
            new Form();
            new PhoneMask('[name="phone"]');
            scrollTo();
        });
    },

    timer: function() {
        // Функция для вычисления разности времени
        function diffSubtract(date1, date2) {
            return date2 - date1;
        }

        // Конечная дата
        let end_date = {
            full_year: '2024', // Год
            months: '07', // Номер месяца
            day: '01', // День
            hours: '00', // Час
            minutes: '00', // Минуты
            seconds: '00' // Секунды
        }

        end_date.minutes = parseInt(end_date.minutes) + 1 < 10 ? `0${parseInt(end_date.minutes) + 1}` : parseInt(end_date.minutes) + 1;

        // Строка для вывода времени
        let end_date_str = `${end_date.full_year}-${end_date.months}-${end_date.day}T${end_date.hours}:${end_date.minutes}:${end_date.seconds}`;

        // Запуск интервала таймера
        let timer = setInterval(function () {
            timerStarter();
        }, 1000);

        timerStarter();

        function timerStarter() {
            // Получение времени сейчас
            let now = new Date();
            // Получение заданного времени
            let date = new Date(end_date_str);
            // Вычисление разницы времени
            let ms_left = diffSubtract(now, date);
            // Если разница времени меньше или равна нулю
            if (ms_left <= 0) {
                // Выключаем интервал
                $('.timer').addClass('show');
                $('.timer__label-counter').text('00');
                clearInterval(timer);
            } else {
                // Получаем разницу
                let res = new Date(ms_left);

                let timerDate = {
                    year: res.getUTCFullYear() - 1970,
                    months: res.getUTCMonth() < 10 ? '0' + res.getUTCMonth() : res.getUTCMonth(),
                    day: res.getUTCDate() - 1,
                    hours: res.getUTCHours() < 10 ? '0' + res.getUTCHours() : res.getUTCHours(),
                    minutes: res.getUTCMinutes() < 10 ? '0' + res.getUTCMinutes() : res.getUTCMinutes(),
                    seconds: res.getUTCSeconds() < 10 ? '0' + res.getUTCSeconds() : res.getUTCSeconds()
                }

                let daysSample = timerDate.months * 30,
                    daysMonth = 0;

                for (var i = 1; i <= timerDate.months; i++) {
                    daysMonth = daysMonth + new Date(now.getYear(),now.getMonth() + i + 1,0).getDate();
                }

                let trueDays = res.getUTCDate() - (daysMonth - daysSample) - 1;

                timerDate.day = trueDays < 10 ? `0${trueDays}` : trueDays;

                let timerString = timerDate.hours + ':' + timerDate.minutes + ':' + timerDate.seconds;

                $('#timer-days').text(timerDate.day);
                $('#timer-time').text(timerString);
                $('#days-label').text(main.declension(timerDate.day, ['день', 'дня', 'дней']));

                if (!$('.timer').hasClass('show')) {
                    $('.timer').addClass('show');
                }
            }
        }
    },


    declension: function(number, words) {
        var cases = [2, 0, 1, 1, 1, 2];
        return (number % 100 > 4 && number % 100 < 20) ? words[2] : words[cases[(number % 10 < 5) ? number % 10 : 5]];
    }
}

main.init();
