class Form {
    constructor() {
        if (this.getUtm().length != 0) {
            $.cookie("utm", this.getUtm());
        }
        this.init();
    }
    reportError(data) {
        window.formErrorApi(data);
        alert('Кажется, что-то пошло не так. Повторите попытку позднее, спасибо.');
    }

    getSearchParams() {
        let paramsString = document.location.search,
            searchParams = new URLSearchParams(paramsString);
        return searchParams;
    }

    getUtm() {
        let utmNames = ['utm_source', 'utm_medium', 'utm_content', 'utm_term', 'utm_campaign'],
            utm = '';
        for (var i = 0; i < utmNames.length; i++) {
            var utmValue = this.getSearchParams().get(utmNames[i]);
            if (utmValue) utm += `&${utmNames[i]}=` + utmValue;
        }
        return utm;
    }

    presetationHandler(urlRedirect) {
        let winRef;
        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        
        if (isSafari) winRef = window.open(window.location.href, "_blank");

        if (isSafari) {
            if (winRef == null) {
                window.location = urlRedirect;
            } else {
                winRef.location = urlRedirect;
            }

        } else {
            window.open(urlRedirect);
        }
    }


    /**
     *Отправка целей в Яндекс.Метрика и Google Analytics
    * */
    g_send_goal(goal, category = null) {
        try {
            console.log('g_send_goal ' + goal + ' ' + category);
            if (typeof(ym) != "undefined") {
                ym(Ya.Metrika2.counters()[0].id, 'reachGoal', goal, null, function () {
                    console.log('ym ok');
                });
            }
            if (typeof(gtag) != "undefined") {
                gtag('event', goal, {
                    'event_category': category,
                    'event_callback': function () {
                        console.log('gtag ok');
                    }
                });
            }
            if (typeof(fbq) !== "undefined") {
                console.log('fbq track Lead');
                fbq('track', 'Lead');
            }
        } catch (e) {
            console.error(e.message);
        }
    }

    init() {
        let self = this;

        $(document).on('click', '[type=submit]', function (e) {
            e.preventDefault();
            let form = $(this).closest('form');
            let field = $(form).find('[required]');
            let submitBtn = form.find('button[type="submit"]');

            let empty = 0;
            
            field.each(function(index, el) {
                if ($(el).val()) {
                    $(el).removeClass('invalid');
                } else {
                    $(el).addClass('invalid');
                    empty++;
                }
            });

            if (empty > 0) return;

            let data = form.serialize();
            
            if (typeof $.cookie('utm') !== 'undefined') {
                data = data + $.cookie('utm');
            }
            
            if ($.cookie('_ym_uid')) {
                data += '&_ym_uid=' + $.cookie('_ym_uid');
            }
            
            data = new URLSearchParams(data);
            data = Object.fromEntries(data.entries());            
            data = JSON.stringify(data);
                
            $.ajax({
                url: 'sendCRM.php',
                method: 'POST',
                data: data,
                beforeSend: function () {
                    submitBtn.addClass('waiting');
                },
                complete: function (jqXHR) {
                    if (!jqXHR.responseJSON || jqXHR.responseJSON.status !== 'form_sent_ok') {
                        reportError(data);
                        submitBtn.removeClass('waiting');
                        return false;
                    }

                    if ($(form).attr('data-goal') && $(form).attr('data-goal-category')){
                        self.g_send_goal($(form).attr('data-goal'), $(form).attr('data-goal-category'));
                    }

                    form.addClass('success');
                    submitBtn.removeClass('waiting');
                }
            });
        })

        $('.contact__form-back').on('click', function () {
            $(this).closest('form').removeClass('success');
        })
    }
}

export default Form;