var scrollToElement = require('scroll-to-element');

function scrollTo() {
    const selector = '[data-scroll-to]';
    const attributeSelctor = 'data-scroll-to';

    document.addEventListener('click', function(e) {
        if (!e.target.closest(selector)) return;
        e.preventDefault();
        const target = e.target.closest(selector).getAttribute(attributeSelctor);

        scrollToElement(target, {
            offset: -50,
            duration: 800
        });
    });
}

export default scrollTo;